'use client';

import Link from 'next/link';
import Header from '@/components/Header';

export default function NotFound() {
    return (
        <>
            <Header />
            <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center px-4">
                {/* Add margin-top to account for fixed header */}
                <div className="text-center max-w-xl mt-16">
                    {/* 404 Icon */}
                    <div className="mb-8">
                        <svg 
                            className="w-24 h-24 text-primary mx-auto"
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth="1.5" 
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                        </svg>
                    </div>

                    {/* Error Message */}
                    <h1 className="text-6xl font-bold text-gray-900 mb-4">
                        404
                    </h1>
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                        Page Not Found
                    </h2>
                    <p className="text-gray-600 mb-8">
                        Sorry, we couldn't find the page you're looking for.
                    </p>

                    {/* Action Buttons */}
                    <div className="flex flex-col sm:flex-row justify-center gap-4">
                        <Link 
                            href="/"
                            className="bg-primary text-white px-6 py-3 rounded-lg hover:bg-primary/90 transition-colors"
                        >
                            Return Home
                        </Link>
                        <Link 
                            href="/contact"
                            className="border-2 border-gray-300 text-gray-700 px-6 py-3 rounded-lg hover:border-primary hover:text-primary transition-colors"
                        >
                            Contact Support
                        </Link>
                    </div>

                    {/* Suggested Links */}
                    <div className="mt-12">
                        <h3 className="text-gray-700 font-medium mb-4">
                            You might be interested in:
                        </h3>
                        <div className="flex flex-wrap justify-center gap-4">
                            <Link 
                                href="/services"
                                className="text-primary hover:text-primary/80 transition-colors"
                            >
                                Our Services
                            </Link>
                            <span className="text-gray-300">•</span>
                            <Link 
                                href="/blog"
                                className="text-primary hover:text-primary/80 transition-colors"
                            >
                                Trade Insights
                            </Link>
                            <span className="text-gray-300">•</span>
                            <Link 
                                href="/about"
                                className="text-primary hover:text-primary/80 transition-colors"
                            >
                                About Us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
} 